<template>
  <div class="course-classify">
    <TopBar :type="1" />
    <div class="menu-bg"></div>
    <ul class="menu">
      <li class="menu-list" :class="{'active':selectedMenu==''}" @click="clickMenu('')">全部</li>
      <li class="menu-list" :class="{'active':selectedMenu.id==item.id}" v-for="item in category" :key="item.id" @click="clickMenu(item)">{{item.name}}</li>
    </ul>
    <ul class="sub-menu">
      <li class="sub-menu-content">
        <p class="menu-title">推荐分类</p>
        <ul class="sub-menu-list">
          <li class="sub-menu-info">文化旅游</li>
          <li class="sub-menu-info">文化旅游</li>
          <li class="sub-menu-info">文化旅游</li>
          <li class="sub-menu-info">文化旅游</li>
          <li class="sub-menu-info">文化旅游</li>
          <li class="sub-menu-info">文化旅游</li>
        </ul>
      </li>
      <li class="sub-menu-content" v-for="item in category" :key="item.id">
        <p class="menu-title">{{item.name}}</p>
        <ul class="sub-menu-list">
          <li class="sub-menu-info" v-for="sub in item.child" :key="sub.id" @click="$router.push('/courseList/'+sub.id)">{{sub.name}}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'CourseClassify',
  data(){
    return {
      category: '',     //分类
      selectedMenu: ''  //选中的一级分类
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getCategory(); //获取分类
  },
  methods: {
    //获取分类
    getCategory(){
      this.$axios.post(`/v1/course/category`).then(res => {
        this.category = res.data;
      });
    },
    //点击一级分类
    clickMenu(item){
      this.selectedMenu = item;
    }
  }
}
</script>
<style scoped lang="scss">
.live{
  ::v-deep .user{
    display: none;
  }
}
.content{
  overflow: hidden;
}
.menu-bg{
  position: fixed;
  width: 198px;
  top: 88px;
  left: 0;
  bottom: 0;
  background: #F5F5F5;
  border-right: 2px solid #E5E5E5;
  z-index: 0;
}
.menu{
  position: fixed;
  width: 200px;
  top: 88px;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
}
.menu-list{
  width: 198px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  &.active{
    width: 198px;
    background-color: #fff;
    border-right: 2px solid #fff;
    position: relative;
    &::after{
      content: '';
      display: block;
      width: 44px;
      height: 4px;
      background: #254ED4;
      border-radius: 1px;
      position: absolute;
      bottom: 17px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
.sub-menu{
  box-sizing: border-box;
  position: fixed;
  width: 550px;
  padding: 0 12px;
  top: 88px;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
}
.sub-menu-content{
  padding-top: 26px;
  .menu-title{
    font-size: 26px;
    color: #666;
    margin: 0 0 22px 17px;
  }
  .sub-menu-list{
    margin-bottom: 34px;
    overflow: hidden;
  }
  .sub-menu-info{
    box-sizing: border-box;
    width: 142px;
    height: 80px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #999;
    background: #F5F5F5;
    border-radius: 6px;
    float: left;
    margin: 0 16px 24px;
  }
}
::v-deep .nav .user{
  display: none;
}
</style>